import { render, staticRenderFns } from "./ContentManage.vue?vue&type=template&id=70ad3197&scoped=true&"
import script from "./ContentManage.vue?vue&type=script&lang=js&"
export * from "./ContentManage.vue?vue&type=script&lang=js&"
import style0 from "./ContentManage.vue?vue&type=style&index=0&id=70ad3197&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70ad3197",
  null
  
)

export default component.exports